<template>
  <pz-form-flujo
    v-model="formulariodocumentacion['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form_documentacion_empresa.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulariodocumentacion"
      status-icon
    >
      <el-row class="center-block text-center">
        <el-col
          v-for="(value, name) in formulariodocumentacion"
          :key="name"
          :xl="{span: 24}"
          :lg="{span: 24}"
          :md="{span: 24}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-col
            :span="22"
            :offset="1"
          >
            <el-form-item
              :prop="name"
              :error="errors.get(name)"
              :rules="getRule(name)"
            >
              <label>{{ settings[name].label_form }}</label>
              <pz-upload
                v-model="formulariodocumentacion[name]"
                :tip="settings[name].hint"
                :template="settings[name].plantilla"
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>

<script>
    import baseFormDocumentacion from "./baseFormDocumentacion";
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzUpload from "@/commons/forms/Fields/PzUpload";


    export default {
        name: 'PzCompanyDocumentacionUy',
        components: {PzUpload, PzFormFlujo},
        mixins: [baseFormDocumentacion],
        data() {
            return {}
        },
        created() {
            this.getDocs('company');
        },
        methods: {}
    }
</script>


