<template>
  <pz-form-flujo
    v-model="descuentoTerceros['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.descuento_terceros_cheque.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="descuentoTerceros"
      status-icon
      :rules="rules_descuentoTerceros"
    >
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="text-center"
        >
          <el-form-item
            prop="personaFisica"
            :error="errors.get('personaFisica')"
          >
            <label class="pz-margin-right-10">
              {{ $t('forms_flujo.descuento_terceros_cheque.librador_type') }}
            </label>
            <el-switch
              v-model="descuentoTerceros.personaFisica"
              active-text="Persona"
              inactive-text="Empresa"
              :active-color="primary"
              :inactive-color="secondary"
              @change="handleLibradorType()"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="text-center"
        >
          <el-form-item
            prop="cheque"
            :error="errors.get('cheque')"
          >
            <label>{{ $t('forms_flujo.descuento_terceros_cheque.doc_label') }} -
              {{ $t('forms_flujo.descuento_terceros_cheque.document') }}</label>
            <pz-upload
              v-model="descuentoTerceros.cheque"
              :tip="$t('forms_flujo.descuento_terceros_cheque.document_warning')"
              tip-icon="warning"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="documento_librador"
            :label="$t('forms_flujo.descuento_terceros_cheque.librador_doc_number')"
            :error="errors.get('documento_librador')"
            :rules="descuentoTerceros.personaFisica ? [Cedula('')] : [Rut('')]"
          >
            <el-input
              v-model="descuentoTerceros.documento_librador"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.librador_doc_number')"
              @change="errors.clear('documento_librador')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombre_librador"
            :label="$t('forms_flujo.descuento_terceros_cheque.librador_name')"
            :error="errors.get('nombre_librador')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.nombre_librador"
              type="text"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.librador_name')"
              @change="errors.clear('nombre_librador')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          v-if="descuentoTerceros.personaFisica"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellido_librador"
            :label="$t('forms_flujo.descuento_terceros_cheque.librador_surename')"
            :error="errors.get('apellido_librador')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.apellido_librador"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.librador_surename')"
              @change="errors.clear('apellido_librador')"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="descuentoTerceros.personaFisica"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_contacto"
            :label="$t('forms_flujo.descuento_terceros_cheque.librador_phone')"
            :error="errors.get('telefono_contacto')"
            class=""
          >
            <el-input
              v-model.number="descuentoTerceros.telefono_contacto"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.librador_phone')"
              @change="errors.clear('telefono_contacto')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="banco_id"
            :label="$t('forms_flujo.descuento_terceros_cheque.bank')"
            :error="errors.get('banco_id')"
            class="bordered"
          >
            <el-select
              v-model="descuentoTerceros.banco_id"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.bank')"
            >
              <el-option
                v-for="item in bancos"
                :key="item.id"
                :label="item.complete_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="numero_cuenta"
            :label="$t('forms_flujo.descuento_terceros_cheque.account_number')"
            :error="errors.get('numero_cuenta')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.numero_cuenta"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.account_number')"
              @change="errors.clear('numero_cuenta')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="numero"
            :label="$t('forms_flujo.descuento_terceros_cheque.number')"
            :error="errors.get('numero')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.numero"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.number')"
              @change="errors.clear('numero')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="serie"
            :label="$t('forms_flujo.descuento_terceros_cheque.serie')"
            :error="errors.get('serie')"
            class=""
          >
            <el-input
              v-model="descuentoTerceros.serie"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.descuento_terceros_cheque.serie')"
              @change="errors.clear('serie')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
import 'element-ui/lib/theme-chalk/display.css'
import baseFormDescuentosTerceros from "./baseFormDescuentosTerceros";
import {Cedula, required, Rut} from "@/commons/utils/Rules";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";

const variables = require(`@/assets/sass/variables_clients/${process.env.VUE_APP_CLIENT}/variables.json`);

export default {
    name: 'PzChequeDescuentoTerceros',
    components: {PzFormFlujo},
    mixins: [baseFormDescuentosTerceros],
    data() {
        return {
            descuentoTerceros: {
                documento_librador: '',
                serie: '',
                numero: '',
                numero_cuenta: '',
                banco_id: '',
                apellido_librador: '',
                nombre_librador: '',
                telefono_contacto: '',
                emision: ''
            },
            filds: {
                apellidos: {label: this.$t('forms_flujo.descuento_terceros_cheque.company_name')}
            },
            rules_descuentoTerceros:
                {
                    apellido_librador: [required('')],
                    nombre_librador: [required('')],
                },
        };
    },
    computed: {
        primary() {
            return variables['--color-primary']
        },
        secondary() {
            return variables['--color-secondary']
        }
    },
    created() {
    },
    mounted() {
        this.handleLibradorType();
    },
    methods: {
        Cedula,
        Rut,
        handleLibradorType() {
            this.filds.apellidos.label = this.$t('forms_flujo.descuento_terceros_cheque.librador_surename');
        }
    }
}
</script>

