<script>
    import {getBancos} from '@/routes/api/resources';
    import {required} from "@/commons/utils/Rules";
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

    export default {
        name: 'PzBaseFormDescuentos',
        mixins: [baseFormFlujo],
        data() {
            return {
                reference: 'descuentoTerceros',
                bancos: [],
                descuentoTerceros: {
                    personaFisica: false,
                    cheque: '',
                },
                rules_descuentoTerceros: {
                    cheque: [required('Copia del documento')],
                }
            }
        },
        beforeMount() {
            getBancos().then((res) => {
                this.bancos = res.data;

            }).catch(() => {
                this.bancos = [];

            });
        },
        mounted() {
            this.descuentoTerceros.cheque = '';
        },
        methods: {},
    }
</script>


