<template>
  <pz-form-flujo
    v-model="formulariodocumentacion['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
    :nameforward="nameboton"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form_documentacion_persona.title') }}
    </span>
    <span slot="form_subtitle">
      {{ $t('forms_flujo.form_documentacion_persona.sub_title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulariodocumentacion"
      status-icon
      :rules="rules_formulariodocumentacion"
    >
      <el-row class="center-block text-center">
        <el-col
          v-for="(value, name) in formulariodocumentacion"
          :key="name"
          :xl="{span: 24}"
          :lg="{span: 24}"
          :md="{span: 24}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-col
            :span="22"
            :offset="1"
          >
            <el-form-item
              :prop="name"
              :error="errors.get(name)"
              :label="settings[name].label_form"
              :rules="settings[name].required?[required('')]:[]"
            >
              <pz-upload
                v-model="formulariodocumentacion[name]"
                :tip="settings[name].hint"
                :template="settings[name].plantilla"
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
import baseFormDocumentacion from "./baseFormDocumentacion";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import {required} from "@/commons/utils/Rules";

export default {
    name: 'PzPersonDocumentacionUy',
    components: {PzFormFlujo},
    mixins: [baseFormDocumentacion],
    data() {
        return {
            nameboton:this.$t('forms_flujo.form_documentacion_persona.name_button')
        }
    },
    created() {
        this.getDocs('person');
    },
    methods: {
        required,
    }
}
</script>