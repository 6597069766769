<template>
  <el-col :span="24">
    <pz-title>
      <span slot="title">
            {{ $t('views.final_vehiculo.greetings') }}
      </span>
      <span slot="sub_title">
          {{ $t('views.final_vehiculo.sent_ok') }}
      </span>
    </pz-title>
    <el-row
      :gutter="20"
      class="pz-font-size-md"
    >
      <el-col
        :span="18"
        :offset="3"
        class="text-center"
      >
        <el-col :span="24">
          {{ $t('views.final_vehiculo.data_received') }}
        </el-col>
        <el-col :span="24">
          <p
            v-for="(value,index) in activities2"
            :key="index"
            style="font-size: 0.8em"
          >
            {{ value }}
          </p>
        </el-col>
      </el-col>
    </el-row>

    <!--<el-row>-->
      <!--<el-col-->
        <!--:span="24"-->
        <!--class="row justify-content-center"-->
      <!--&gt;-->
        <!--<pz-back-button-->
          <!--:back="goFirstStep"-->
          <!--:button-name="$t('views.final.back_button')"-->
        <!--/>-->
      <!--</el-col>-->
    <!--</el-row>-->
  </el-col>
</template>


<script>
    import {mapGetters} from "vuex";
    import { checkFinal  } from "@/routes/api/views/flujo";
    export default {
        name: 'PzMessageVehiculo',
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(['product', 'form_product', 'current_step']),
        },
        created() {
            this.activities2 = Object.values(this.$t('views.final_vehiculo.data_received_2')).map((value) => {
                return value;
            })
            this.getRevisionFinal();
            this.$store.dispatch('loader/down',{trigger:'route_enter'});
        },
        methods: {
            goFirstStep: function () {
                this.$emit('save');
            },
            getRevisionFinal() {
                this.$store.dispatch('loader/up',{trigger:this.$options.name})
                let params = {
                    'loan':this.form_product.loan
                }
                checkFinal(params);
            }
        },
    }
</script>

<style scoped>

</style>